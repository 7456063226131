import React from "react";
import ConnectedSettingsData from "./BandwidthTools/ConnectedSettingsData";
import LaunchApprovalTools from "./LaunchApproval/LaunchApprovalTools";
import { TextTools } from "./TextTools/TextTools";
import ScriptTools from "./ScriptTools/ScriptTools";

export const SystemAdminTools = ({ 
  selectedCampaignData, 
  userPermissions,
}) => {

  const isActiveCampaign = selectedCampaignData.active !== "deactivated";

  return (
    <div>
      
      { isActiveCampaign && userPermissions.includes("VIEW_CAMPAIGN_LAUNCH_APPROVAL_TOOLS") && (
        <>
          <LaunchApprovalTools
            active={selectedCampaignData.active}
            selectedCampaignData={selectedCampaignData}
          />
          <hr />
        </>
      )}

      <TextTools
        campaignid={selectedCampaignData.campaignid}
        active={selectedCampaignData.active}
        assignOpenRecipients={selectedCampaignData.assign_open_recipients}
        recipientbatchsize={selectedCampaignData.recipientbatchsize}
        actionablethreshold={selectedCampaignData.actionablethreshold}
        limitNewConversationsPerHour={selectedCampaignData.limit_new_first_attempts_hourly}
        hourlyNewConversationLimit={selectedCampaignData.hourly_maximum_first_attempts}
      />

      <hr />

      <ScriptTools
        active={selectedCampaignData.active}
        partialScriptID={selectedCampaignData.partial_complete_script_id}
        partialAnswerList={selectedCampaignData.partial_complete_answer_list}
        script={selectedCampaignData.script}
      />

      { isActiveCampaign && userPermissions.includes("VIEW_BANDWIDTH_REGISTRATION_TOOLS") && (
        <>
          <hr />
          { // Telnyx (experimental)
            selectedCampaignData.aggregator == 'telnyx' ? (
            <>
              <h4 className="title is-4 has-text-centered">TELNYX REGISTRATION</h4>
              <article class="message is-warning mb-5">
                <div class="message-body">
                  <p>This experimental campaign is using Telnyx instead of Bandwidth to send messages.</p>
                    <p>To view or edit the
                    Telnyx <a href="https://portal.telnyx.com/#/programmable-messaging/profiles" target="_blank">messaging profile</a> and
                    the <a href="https://portal.telnyx.com/#/numbers/my-numbers" target="_blank" >phone numbers</a>, use their dashboard.</p>
                </div>
              </article>
            </>
            ) : (
            // Default to Bandwidth
            <ConnectedSettingsData
              campaignid={selectedCampaignData.campaignid}
              pnpercopilot={selectedCampaignData.pnpercopilot}
              bw_subaccount_id={selectedCampaignData.bw_subaccount_id}
              bw_location_id={selectedCampaignData.bw_location_id}
              bw_registration_id={selectedCampaignData.bw_registration_id}
              has_unassigned_recipients={selectedCampaignData.has_unassigned_recipients}
              has_unregistered_outgoing_lines={selectedCampaignData.has_unregistered_outgoing_lines}
            />
          )}
        </>
      )}
    </div>
  );
};
